/**
 *
 * @param locale
 * @example
 * const englishWeekdays = getWeekdays('en-US');
 * const spanishWeekdays = getWeekdays('es-ES');
 * const frenchWeekdays = getWeekdays('fr-FR');
 */
export const getWeekdays = (locale: string = 'en-US') => {
  const baseDate = new Date(2024, 0, 7); // January 7, 2024 is a Sunday
  const weekdays = [];

  for (let i = 0; i < 7; i++) {
    weekdays.push(
      new Intl.DateTimeFormat(locale, { weekday: 'long' }).format(
        new Date(baseDate.getTime() + i * 24 * 60 * 60 * 1000)
      )
    );
  }

  return weekdays;
};
