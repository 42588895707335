import React, { useState } from 'react';
import { Card, CardHeader } from '../components/Card';
import { ROUTES } from '../config/routes';
import { ReactComponent as Download } from '../assets/icons/download.svg';

const Home: React.FC = () => {
  const [showComingSoon, setShowComingSoon] = useState(false);

  const handleMarketingClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowComingSoon(true);
    
    // Reset after 5 seconds
    setTimeout(() => {
      setShowComingSoon(false);
    }, 5000);
  };

  return (
    <>
      <div className="mb-8 lg:mb-20">
        <h2 className="text-black/60">Welcome back</h2>
        {/* TODO:Status */}
      </div>
      <div className="grid-dashboard grid lg:grid-cols-4 gap-4 lg:gap-y-14 auto-rows-[125px] lg:grid-rows-[repeat(2,_minmax(172px,_1fr))] ">
        <Card to={ROUTES.TRANSACTIONS()} variant="secondary">
          <CardHeader title="Transactions" description="All transactions" />
        </Card>
        <Card to={ROUTES.PROFILE()} variant="tertiary">
          <CardHeader title="Profile" description="Update your app profile" />
        </Card>

        <Card to={ROUTES.INSIGHTS()}>
          <CardHeader
            title="Insights"
            description="Consumer demographics and data"
          />
        </Card>
        <Card 
          to="/" 
          variant="quaternary"
          onClick={handleMarketingClick}
        >
          {showComingSoon ? (
            <div className="flex flex-col items-center justify-center h-full text-center p-4">
              <p className="text-lg font-medium mb-2">Coming Soon!</p>
              <p className="text-sm text-gray-600">Stay tuned for exciting marketing opportunities.</p>
            </div>
          ) : (
            <CardHeader
              title="Upgrade Marketing Package"
              description="Purchase a booster to promote your business"
            />
          )}
        </Card>
        <Card to={ROUTES.BILLING()} variant="quinary">
          <CardHeader
            title="Invoice Overview"
            description="See all your invoices"
          />
        </Card>
        <Card to="/" variant="outline" icon={<Download />}>
          <CardHeader title="Download report" />
        </Card>
      </div>
    </>
  );
};

export default Home;
