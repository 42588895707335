import React, { useState, useEffect } from 'react';
import {
  updateExternalLinkCustom,
  createExternalLinkCustom,
  getExternalLinksCustom,
} from '../api/externalLinks';
import { Button } from './Button';
import { TextField } from './TextField';

type CustomLinksFormState = {
  title: string;
  url: string;
};

type CustomLinkFormContentProps = {
  id?: string;
  onSubmit: (id: string) => Promise<void>;
  onCancel: () => void;
  onPendingChangesUpdate: (hasPendingChanges: boolean) => void;
};

const CustomLinksForm: React.FC<CustomLinkFormContentProps> = ({
  id = null,
  onSubmit,
  onCancel,
  onPendingChangesUpdate
}) => {
  const [input, setInput] = useState<CustomLinksFormState>({
    title: '',
    url: '',
  });
  const [error, setError] = useState<string | null>(null);

  const isUpdating = !!id;

  useEffect(() => {
    const fetchData = async () => {
      if (!isUpdating) return;
      try {
        const data = await getExternalLinksCustom(id!);
        setInput({ ...data });
        onPendingChangesUpdate(data.hasPendingChanges? data.hasPendingChanges && isUpdating : false);
        setError(null);
      } catch (err) {
        setError('Failed to fetch Custom Links data');
        console.error('Error fetching Custom Links data:', err);
      }
    };

    fetchData();
  }, [id, isUpdating]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (id) {
        const response = await updateExternalLinkCustom(id, input);
        onSubmit(response.id);
      } else {
        const response = await createExternalLinkCustom(input);
        onSubmit(response.id);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    }
  };

  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="">
      <form onSubmit={handleSubmit} className="flex flex-col gap-4 h-full ">
        <TextField
          type="text"
          id="title"
          name="title"
          label="Title"
          value={input.title}
          onChange={handleChange}
          required
        />

        <TextField
          id="url"
          name="url"
          type="url"
          label="Url"
          pattern="https://.*"
          value={input.url}
          onChange={handleChange}
          required
        />

        <div className="flex gap-4 mt-auto flex-1">
          <Button type="submit" className="btn btn-primary" size="full">
            {id ? 'Update' : 'Create'}
          </Button>
          <Button
            type="button"
            className="btn btn-secondary"
            size="full"
            variant="light"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </form>
      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
};

export default CustomLinksForm;
