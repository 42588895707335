import React, { useState, useEffect } from 'react';
import { updateCompany, UpdateCompanyInput, getCompany } from '../api/company';
import { useNavigate } from 'react-router-dom';
import { TextField } from './TextField';
import { Button } from './Button';
import { Loader } from './Loader';

type CompanyFormState = {
  name: string;
  email: string;
  cvrNumber: string;
};

const CompanyForm: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [input, setInput] = useState<CompanyFormState>({
    name: '',
    email: '',
    cvrNumber: '',
  });
  const [hasPendingChanges, setHasPendingChanges] = useState<boolean>();

  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await getCompany();
      setInput(data);
      setHasPendingChanges(data.hasPendingChanges ?? false)
      setError(null);
    } catch (err) {
      setError('Failed to fetch company data');
      console.error('Error fetching company data:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const submitData: UpdateCompanyInput = {
        ...input,
      };

      await updateCompany(submitData);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading)
    return (
      <div className="loading-page">
        <Loader />
      </div>
    );
  if (error) return <div>{error}</div>;

  return (
    <div className="lg:w-1/2">
      <h2 className="font-medium mb-14">Update company details
      {hasPendingChanges && (
        <span className="ml-2 text-sm text-orange-500">(Pending changes)</span>
      )}
      </h2>
      {error && <div className="error">{error}</div>}
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 bg-gray-50 rounded-2xl p-8 [&>label]:flex-1 "
      >
        <TextField
          type="text"
          id="name"
          label="Name"
          name="name"
          value={input.name}
          onChange={handleChange}
          required
        />
        <TextField
          type="text"
          id="email"
          name="email"
          label="Email"
          value={input.email}
          onChange={handleChange}
          required
        />
        <TextField
          type="text"
          id="cvrNumber"
          name="cvrNumber"
          label="CVR number"
          value={input.cvrNumber}
          onChange={handleChange}
          required
          className="mb-4"
        />

        <Button type="submit" size="lg" isLoading={isSubmitting}>
          Save changes
        </Button>
      </form>
    </div>
  );
};

export default CompanyForm;
