import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import Modal from 'react-modal';
import ExternalLinksForm from './ExternalLinksForm';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  createBusiness,
  updateBusiness,
  getBusiness,
  CreateBusinessInput,
} from '../api/business';
import {
  listBusinessCategories,
  BusinessCategory,
} from '../api/businessCategories';
import { listBenefits, Benefit } from '../api/benefits';
import { listPaymentProviders, PaymentProvider } from '../api/paymentProviders';
import { uploadImage, getImage } from '../api/images';
import MapPicker from './MapPicker';
import { TextField } from './TextField';
import { TextArea } from './TextArea';
import { Button } from './Button';
import { Select } from './Select';
import { ImageUpload } from './ImageUpload';
import { ReactComponent as CloseIcon } from '../assets/icons/x.svg';
import { Label } from './Label';

export type Image = {
  id: string;
  title: string;
  url: string;
  contentType: string;
  width: number;
  height: number;
};

interface BusinessFormState {
  id?: string;
  name: string;
  merchantContractNumbers: string[];
  businessCategoryIds: string[];
  description: string;
  location: { lon: number; lat: number } | null;
  logo: Image | null;
  mainImage: Image | null;
  images: Image[];
  benefitIds: string[];
  paymentProviderIds: string[];
  externalLinksId: string;
  billingIdentificationNumber: string;
  alternativeMerchantNames: string[];
}

const BusinessForm: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [hasPendingChanges, setHasPendingChanges] = useState<boolean>();
  const [hasPendingChangesInExternalLinksModal, setHasPendingChangesInExternalLinksModal] = useState<boolean>();
  const [input, setInput] = useState<BusinessFormState>({
    name: '',
    merchantContractNumbers: [],
    businessCategoryIds: [],
    description: '',
    location: null,
    logo: null,
    mainImage: null,
    images: [],
    benefitIds: [],
    paymentProviderIds: [],
    externalLinksId: '',
    billingIdentificationNumber: '',
    alternativeMerchantNames: [],
  });
  const [isExternalLinksModalOpen, setIsExternalLinksModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<BusinessCategory[]>([]);
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [paymentProviders, setPaymentProviders] = useState<PaymentProvider[]>(
    []
  );
  const [error, setError] = useState<string | null>(null);

  const isUpdating = !!id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesData, benefitsData, providersData] = await Promise.all(
          [listBusinessCategories(), listBenefits(), listPaymentProviders()]
        );
        setCategories(categoriesData);
        setBenefits(benefitsData);
        setPaymentProviders(providersData);

        if (id) {
          const businessData = await getBusiness(id);
          setInput({
            ...businessData,
            benefitIds: businessData.benefits.map((b) => b.id),
            businessCategoryIds: businessData.businessCategories.map(
              (cat) => cat.id
            ),
            paymentProviderIds: businessData.paymentProviders.map((p) => p.id),
            externalLinksId: businessData.externalLinks
              ? businessData.externalLinks.id
              : '',
          });
          setHasPendingChanges(businessData.hasPendingChanges ?? false)
          console.log(businessData.hasPendingChanges)
        }
      } catch (err) {
        setError('Failed to fetch data');
        console.error(err);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleArrayChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof BusinessFormState
  ) => {
    const values = e.target.value.split(',').map((v) => v.trim());
    setInput((prev) => ({ ...prev, [field]: values }));
  };

  const handleMultiSelect =
    (field: keyof BusinessFormState) => (value: string | string[]) => {
      setInput((prev) => ({ ...prev, [field]: value }));
    };

  const handleAddImage = async (file: File | null) => {
    if (file) {
      try {
        const id = (await uploadImage(file)).id;
        const newImage = await getImage(id);
        setInput((prev) => ({
          ...prev,
          images: [...prev.images, newImage],
        }));
      } catch (error) {
        console.error('Error uploading image:', error);
        setError('Failed to upload image');
      }
    }
  };

  const handleRemoveImage = (id: string) => {
    setInput((prev) => ({
      ...prev,
      images: prev.images.filter((img) => img.id !== id),
    }));
  };

  const handleRemoveLogo = () => {
    setInput((prev) => ({
      ...prev,
      logo: null,
    }));
  };

  const handleRemoveMainImage = () => {
    setInput((prev) => ({
      ...prev,
      mainImage: null,
    }));
  };

  const handleImageChange =
    (field: 'logo' | 'mainImage') => async (file: File | null) => {
      if (file) {
        try {
          const id = (await uploadImage(file)).id;

          const newImage = await getImage(id);

          setInput((prev) => ({ ...prev, [field]: newImage }));
        } catch (error) {
          console.error('Error uploading image:', error);
          setError('Failed to upload image');
        }
      }
    };

  const handleOpenExternalLinksModal = () => {
    setIsExternalLinksModalOpen(true);
  };

  const handleCloseExternalLinksModal = () => {
    setIsExternalLinksModalOpen(false);
  };

  const handleExternalLinksSubmit = async (linkId: string) => {
    setInput((prev) => ({
      ...prev,
      externalLinksId: linkId,
    }));
    handleCloseExternalLinksModal();
  };

  const handleLocationChange = (newLocation: { lat: number; lng: number }) => {
    setInput((prev) => ({
      ...prev,
      location: { lat: newLocation.lat, lon: newLocation.lng },
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const submitData: CreateBusinessInput = {
        ...input,
        logoId: input.logo?.id || '',
        mainImageId: input.mainImage?.id || '',
        imageIds: input.images.map((img) => img.id),
      };
      if (id) {
        await updateBusiness(id, submitData);
      } else {
        await createBusiness(submitData);
      }
      navigate('/businesses');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="pb-10">
      <div className="mb-6">
        <h2 className="mb-4 font-medium">
        {isUpdating ? 'Update Business' : 'Create New Business'}
        {isUpdating && hasPendingChanges && (
          <span className="ml-2 text-sm text-orange-500">(Pending changes)</span>
        )}
        </h2>
        {/* Breadcrumbs */}
      </div>

      <Modal
        className="modal-content"
        overlayClassName="modal-overlay"
        isOpen={isExternalLinksModalOpen}
        onRequestClose={handleCloseExternalLinksModal}
        contentLabel="External Links"
      >
        <div className="flex  justify-between mb-6">
          <h2 className="text-primary font-medium text-center">
            External Links
            {hasPendingChangesInExternalLinksModal && (
              <span className="ml-2 text-sm text-orange-500">(Pending changes)</span>
            )}
          </h2>
          <Button
            onClick={handleCloseExternalLinksModal}
            className=""
            variant="icon"
          >
            <CloseIcon className="w-3 h-3" />
          </Button>
        </div>
        <ExternalLinksForm
          id={input.externalLinksId}
          onSubmit={handleExternalLinksSubmit}
          onCancel={handleCloseExternalLinksModal}
          onPendingChangesUpdate={setHasPendingChangesInExternalLinksModal}
        />
      </Modal>
      <form
        onSubmit={handleSubmit}
        className="bg-gray-50 flex flex-col gap-4  rounded-2xl p-8"
      >
        <div className="grid lg:grid-cols-2 gap-x-[30px] gap-y-4">
          <TextField
            type="text"
            id="name"
            name="name"
            label="Name"
            value={input.name}
            onChange={handleChange}
            required
          />

          {benefits && benefits.length > 0 ? (
            <Select
              multiple
              label="Benefits"
              value={input.benefitIds}
              onChange={handleMultiSelect('benefitIds')}
              values={benefits.map((b) => ({ label: b.name, value: b.id }))}
            />
          ) : (
            <div className="text-sm text-red-500 flex items-center">
              Please add benefits first before completing
            </div>
          )}

          {categories && categories.length > 0 && (
            <Select
              multiple
              value={input.businessCategoryIds}
              values={categories?.map((c) => ({ label: c.name, value: c.id }))}
              onChange={handleMultiSelect('businessCategoryIds')}
              label={
                <Label additionalText="(required)">Business Categories</Label>
              }
            />
          )}

          {paymentProviders && paymentProviders.length > 0 && (
            <Select
              multiple
              label="Payment Providers"
              value={input.paymentProviderIds}
              onChange={handleMultiSelect('paymentProviderIds')}
              values={paymentProviders.map((p) => ({
                label: p.name,
                value: p.id,
              }))}
            />
          )}

          <TextArea
            id="description"
            label="Description"
            name="description"
            className="min-h-20 thin-scrollbar"
            value={input.description}
            onChange={handleChange}
            required
          />

          <TextField
            type="text"
            id="alternativeMerchantNames"
            name="alternativeMerchantNames"
            label={
              <Label additionalText="(comma-separated)">
                Alternative Merchant Names
              </Label>
            }
            value={input.alternativeMerchantNames.join(', ')}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleArrayChange(e, 'alternativeMerchantNames')
            }
          />

          <TextField
            type="text"
            id="merchantContractNumbers"
            name="merchantContractNumbers"
            label={
              <Label additionalText="(comma-separated)">
                Merchant Contract Numbers
              </Label>
            }
            value={input.merchantContractNumbers.join(', ')}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleArrayChange(e, 'merchantContractNumbers')
            }
          />

          <TextField
            label="Billing Identification Number"
            id="billingIdentificationNumber"
            name="billingIdentificationNumber"
            value={input.billingIdentificationNumber}
            onChange={handleChange}
            required
          />
        </div>

        {/* Images */}
        <div className="grid lg:grid-cols-2 gap-x-[30px] gap-y-4">
          <div className="flex flex-col gap-4">
            <ImageUpload
              images={input.logo ? [input.logo] : undefined}
              onChange={handleImageChange('logo')}
              onDelete={handleRemoveLogo}
              label={<Label additionalText="(dimensions 1:1)">Logo</Label>}
            />

            <ImageUpload
              images={input.mainImage ? [input.mainImage] : undefined}
              onChange={handleImageChange('mainImage')}
              onDelete={handleRemoveMainImage}
              label={
                <Label additionalText="(dimensions 9:10)">Featured image</Label>
              }
            />
            <ImageUpload
              images={input.images}
              onChange={handleAddImage}
              onDelete={handleRemoveImage}
              multiple
              label={
                <Label additionalText="(dimensions 4:3)">
                  Additional Images
                </Label>
              }
            />
          </div>

          <div className="">
            <Label additionalText="(required)">External Links</Label>
            <Button
              type="button"
              onClick={handleOpenExternalLinksModal}
              className="mt-1"
            >
              {input.externalLinksId
                ? 'Edit External Links'
                : 'Add External Links'}
            </Button>
          </div>
        </div>

        <div>
          <h2 className="text-sm mb-0.5">Location</h2>
          <MapPicker
            location={
              input.location
                ? { ...input.location, lng: input.location.lon }
                : null
            }
            onChange={handleLocationChange}
          />
          {input.location && (
            <div className="mt-2">
              Lat: {input.location.lat.toFixed(6)}, Lng:{' '}
              {input.location.lon.toFixed(6)}
            </div>
          )}
        </div>

        <Button
          type="submit"
          className="btn btn-primary submit-btn w-1/2"
          disabled={benefits.length === 0}
          isLoading={loading}
        >
          {id ? 'Apply for changes' : 'Apply to create new business'}
        </Button>
        {error && <div className="text-red-500 mt-4 col-span-2">{error}</div>}
      </form>
    </div>
  );
};

export default BusinessForm;
