import React, { FC, PropsWithChildren } from 'react';
import Navigation from '../Navigation';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ROUTES } from '../../config/routes';
import { Link } from 'react-router-dom';

export const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="bg-white flex flex-col lg:flex-row min-h-screen">
      <aside className="w-full flex flex-col lg:fixed lg:w-[300px] lg:h-screen lg:border-r lg:border-primary/30 p-4">
        <div className="flex flex-col h-full">
          <Link to={ROUTES.HOME()} className="mb-10 lg:mb-24">
            <Logo />
          </Link>
          <div className="flex-1">
            <Navigation />
          </div>
        </div>
      </aside>
      <main className="flex-1 lg:pl-[300px]">
        <section className="container mx-auto pt-4 pb-10 lg:py-10 px-4">
          {children}
        </section>
      </main>
    </div>
  );
};
