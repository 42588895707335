import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Modal from 'react-modal';
import Home from './pages/Home';
import Settings from './pages/Settings';
import BenefitsList from './pages/BenefitsList';
import BenefitForm from './components/BenefitForm';
import BusinessList from './pages/BusinessList';
import BusinessForm from './components/BusinessForm';
import { ROUTES } from './config/routes';
import { ResetPassword } from './pages/ResetPassword';
import { RequestPasswordReset } from './pages/RequestPasswordReset';
import { Login } from './pages/Login';
import Profile from './pages/Profile';
import { ProtectedRouts } from './components/ProtectedRouts';
import { AuthLayout } from './components/layouts/AuthLayout';
import Transactions from './pages/Transactions';
import Insights from './pages/Insights';
import TermsOfService from './pages/TermsOfService';
import Billing from './pages/Billing';
Modal.setAppElement('#root');

const App: React.FC = () => {
  return (
    <>
      <Routes>
        <Route element={<ProtectedRouts />}>
          <Route path={ROUTES.HOME()} element={<Home />} />
          <Route path={ROUTES.PROFILE()} element={<Profile />} />
          <Route path={ROUTES.SETTINGS()} element={<Settings />} />
          <Route path={ROUTES.BENEFITS()} element={<BenefitsList />} />
          <Route path={ROUTES.BENEFITS_NEW()} element={<BenefitForm />} />
          <Route path={ROUTES.BENEFITS_ID()} element={<BenefitForm />} />
          <Route path={ROUTES.BUSINESSES()} element={<BusinessList />} />
          <Route path={ROUTES.BUSINESSES_NEW()} element={<BusinessForm />} />
          <Route path={ROUTES.BUSINESSES_ID()} element={<BusinessForm />} />
          <Route path={ROUTES.TRANSACTIONS()} element={<Transactions />} />
          <Route path={ROUTES.INSIGHTS()} element={<Insights />} />
          <Route path={ROUTES.TERMS_OF_SERVICE()} element={<TermsOfService />} />
          <Route path={ROUTES.BILLING()} element={<Billing />} />
        </Route>

        <Route element={<AuthLayout />}>
          <Route path={ROUTES.LOGIN()} element={<Login />} />
          <Route
            path={ROUTES.REQUEST_PASSWORD_RESET()}
            element={<RequestPasswordReset />}
          />
          <Route path={ROUTES.RESET_PASSWORD()} element={<ResetPassword />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
