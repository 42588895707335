import api from './axiosConfig';

export interface Benefit {
  id: string;
  name: string;
  totalCashbackValue: number;
  customerCashbackValue: number;
  startDateTime: string;
  endDateTime: string | null;
  weekdays: number[];
  blacklistAmount: number[];
  whitelistAmount: number[];
  takeAmountAbove: number | null;
  takeAmountBelow: number | null;
  startTime: string | null;
  endTime: string | null;
  hasPendingChanges: boolean | null;
}

export interface CreateBenefitInput {
  name: string;
  totalCashbackValue: number;
  customerCashbackValue: number;
  startDateTime: string;
  endDateTime: string | null;
  weekdays: number[];
  blacklistAmount: number[];
  whitelistAmount: number[];
  takeAmountAbove: number | null;
  takeAmountBelow: number | null;
  startTime: string | null;
  endTime: string | null;
}

export interface UpdateBenefitInput extends CreateBenefitInput {
  id: string;
}

export const listBenefits = async (): Promise<Benefit[]> => {
  try {
    const response = await api.post('/business-account/list-benefits', {});
    return response.data;
  } catch (error) {
    console.error('Listing benefits error:', error);
    throw error;
  }
};

export const createBenefit = async (input: CreateBenefitInput) => {
  try {
    const response = await api.post(
      '/business-account/create-or-update-benefit',
      {
        CreateOrUpdateBenefitInput: input,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Creating benefit error:', error);
    throw error;
  }
};

export const updateBenefit = async (input: UpdateBenefitInput) => {
  try {
    const response = await api.post(
      '/business-account/create-or-update-benefit',
      {
        CreateOrUpdateBenefitInput: input,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Creating benefit error:', error);
    throw error;
  }
};

export const getBenefit = async (id: string): Promise<Benefit> => {
  try {
    const response = await api.post('/business-account/get-benefit', {
      BenefitId: id,
    });
    return response.data;
  } catch (error) {
    console.error('Getting benefit error:', error);
    throw error;
  }
};
