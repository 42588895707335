import api from "./axiosConfig";

export interface AgeGroup {
  ageRange: string;
  customersCount: number;
}

export interface GenderGroup {
  gender: 'NotSelected';
  customersCount: number;
}

export interface ScreenViewGroup {
  platform: string;
  screenViews: number;
}

export interface CompanyInsights {
  totalCustomersCount: number;
  ageGroups: AgeGroup[];
  genderGroups: GenderGroup[];
  screenViewGroups: ScreenViewGroup[];
}

export interface GlobalInsights {
  totalCustomersCount: number;
  ageGroups: AgeGroup[];
  genderGroups: GenderGroup[];
  screenViewGroups: ScreenViewGroup[];
}

export interface InsightsResponse {
  companyInsights: CompanyInsights;
  globalInsights: GlobalInsights;
}

export const getInsights = async (): Promise<InsightsResponse> => {
  try {
    const response = await api.post('/business-account/get-insights', {});
    return response.data;
  } catch (error) {
    console.error('Getting insights error:', error);
    throw error;
  }
}; 