import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { login, getMyself, logout, User, LoginCredentials, JwtPayload } from '../api/auth';
import { jwtDecode } from "jwt-decode";
import { getTermsOfService } from '../api/termsOfService';
import { getBillingInfo } from '../api/billing';

interface AuthContextType {
  user: User | null;
  login: (credentials: LoginCredentials) => Promise<void>;
  logout: () => Promise<void>;
  loading: boolean;
  termsAccepted: boolean | null;
  checkTermsAcceptance: () => Promise<void>;
  isActionRequired: boolean;
  checkBillingStatus: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState<boolean | null>(null);
  const [isActionRequired, setActionRequired] = useState(false);

  const checkTermsAcceptance = async () => {
    try {
      const response = await getTermsOfService();
      setTermsAccepted(response.accepted);
    } catch (error) {
      console.error("Error checking terms acceptance:", error);
      setTermsAccepted(null);
    }
  };

  const checkBillingStatus = async () => {
    try {
      const response = await getBillingInfo();
      setActionRequired(response.billingInfo?.actionRequired ?? false);
    } catch (error) {
      console.error("Error checking billing status:", error);
      setActionRequired(false);
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await getMyself();
        setUser(user);
        await Promise.all([checkTermsAcceptance(), checkBillingStatus()]);
      } catch (error) {
        setUser(null);
        setTermsAccepted(null);
        setActionRequired(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const loginUser = async (credentials: LoginCredentials) => {
    await login(credentials);
    const user = await getMyself();
    setUser(user);
    await checkTermsAcceptance();
  };

  const logoutUser = async () => {
    await logout();
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login: loginUser,
        logout: logoutUser,
        loading,
        termsAccepted,
        checkTermsAcceptance,
        isActionRequired: isActionRequired,
        checkBillingStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};