import React, { useState, useEffect } from "react";
import { getBillingInfo, BillingInfo, getPaymentMethodForm, getCustomerBillingPortalAccess, listInvoices, Invoice } from "../api/billing";
import { Button } from "../components/Button";
import { Loader } from "../components/Loader";
import { ReactComponent as CreditCard } from "../assets/icons/credit-card.svg";
import { ReactComponent as EditIcon } from "../assets/icons/edit.svg";
import { ReactComponent as ViewIcon } from "../assets/icons/view.svg";
import { useAuth } from "../context/AuthContext";

const Billing: React.FC = () => {
  const { isActionRequired, checkBillingStatus } = useAuth();
  const [billingInfo, setBillingInfo] = useState<BillingInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isManagePaymentLoading, setIsManagePaymentLoading] = useState(false);
  const [isAddPaymentLoading, setIsAddPaymentLoading] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [loadingInvoices, setLoadingInvoices] = useState(true);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  useEffect(() => {
    fetchBillingInfo();
    fetchInvoices();
  }, []);

  const fetchBillingInfo = async () => {
    try {
      setLoading(true);
      const response = await getBillingInfo();
      setBillingInfo(response.billingInfo);
      setError(null);

      // Only update global billing status if we need to clear the alert
      if (!response.billingInfo?.actionRequired && isActionRequired) {
        await checkBillingStatus();
      }
    } catch (err) {
      setError("Failed to fetch billing information");
      console.error("Error fetching billing info:", err);
    } finally {
      setLoading(false);
    }
  };

  const fetchInvoices = async () => {
    try {
      setLoadingInvoices(true);
      const response = await listInvoices();
      setInvoices(response.invoices);
      setHasMore(response.hasMore);
    } catch (err) {
      console.error("Error fetching invoices:", err);
    } finally {
      setLoadingInvoices(false);
    }
  };

  const handleAddPaymentMethod = async () => {
    try {
      setIsAddPaymentLoading(true);
      const response = await getPaymentMethodForm();
      window.location.href = response.formUrl;
    } catch (err) {
      setError("Failed to get payment form");
      console.error("Error getting payment form:", err);
    } finally {
      setIsAddPaymentLoading(false);
    }
  };

  const handleManagePaymentMethods = async () => {
    try {
      setIsManagePaymentLoading(true);
      const response = await getCustomerBillingPortalAccess();
      window.open(response.customerPortalUrl, "_blank");
    } catch (err) {
      setError("Failed to access billing portal");
      console.error("Error accessing billing portal:", err);
    } finally {
      setIsManagePaymentLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="loading-page">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!billingInfo) {
    return (
      <div className="w-full bg-yellow-100 border-l-4 border-yellow-500 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-yellow-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              Billing for this account has not been configured by administrator
              yet.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex max-md:flex-col md:items-center md:justify-between mb-10 max-md:gap-4">
        <div>
          <h2 className="font-medium">Billing</h2>
          <p className="text-sm text-gray-500 mt-1">
            Manage your payment methods and billing information
          </p>
        </div>
        <div className="flex gap-2 max-md:w-full">
          <Button
            variant="light"
            className="gap-2"
            onClick={handleManagePaymentMethods}
            disabled={isManagePaymentLoading}
          >
            {isManagePaymentLoading ? (
              <>
                <Loader className="w-4 h-4" />
                Loading...
              </>
            ) : (
              <>
                <EditIcon className="w-4 h-4" />
                Payment Methods
              </>
            )}
          </Button>
        </div>
      </div>

      {(billingInfo.actionRequired) && (
        <div className="mb-6 w-full bg-red-100 border-l-4 border-red-500 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-red-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">
                You have unpaid invoices that require immediate attention.
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white rounded-lg shadow p-6 mb-6">
        <h3 className="text-lg font-medium mb-4">Active Payment Methods</h3>
        {billingInfo.paymentMethods.filter((method) =>
          ["Active", "Pending"].includes(method.state)
        ).length === 0 ? (
          <div className="flex flex-col items-center py-4">
            <p className="text-gray-500 mb-4">No active payment methods</p>
            <Button
              variant="primary"
              className="gap-2"
              onClick={handleAddPaymentMethod}
              disabled={isAddPaymentLoading}
            >
              {isAddPaymentLoading ? (
                <>
                  <Loader className="w-4 h-4" />
                  Loading...
                </>
              ) : (
                <>
                  <CreditCard className="w-4 h-4" />
                  Add Payment Method
                </>
              )}
            </Button>
          </div>
        ) : (
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            {billingInfo.paymentMethods
              .filter((method) => ["Active", "Pending"].includes(method.state))
              .map((method, index) => (
                <div
                  key={index}
                  className="border rounded-lg p-4 flex flex-col gap-2"
                >
                  <div className="flex items-center justify-between">
                    <span className="font-medium">{method.cardProvider}</span>
                    <span
                      className={`text-sm px-2 py-1 rounded ${
                        method.state === "Active"
                          ? "bg-green-100 text-green-800"
                          : method.state === "Pending"
                          ? "bg-yellow-100 text-yellow-800"
                          : method.state === "Failed"
                          ? "bg-red-100 text-red-800"
                          : "bg-gray-100 text-gray-800"
                      }`}
                    >
                      {method.state}
                    </span>
                  </div>
                  <span className="text-gray-600">
                    {method.maskedCardNumber}
                  </span>
                </div>
              ))}
          </div>
        )}
      </div>

      {billingInfo.paymentMethods.some(
        (method) => !["Active", "Pending"].includes(method.state)
      ) && (
        <div className="bg-white rounded-lg shadow p-6 mb-6">
          <h3 className="text-lg font-medium mb-4">Inactive Payment Methods</h3>
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            {billingInfo.paymentMethods
              .filter((method) => !["Active", "Pending"].includes(method.state))
              .map((method, index) => (
                <div
                  key={index}
                  className="border rounded-lg p-4 flex flex-col gap-2"
                >
                  <div className="flex items-center justify-between">
                    <span className="font-medium">{method.cardProvider}</span>
                    <span
                      className={`text-sm px-2 py-1 rounded ${
                        method.state === "Active"
                          ? "bg-green-100 text-green-800"
                          : method.state === "Pending"
                          ? "bg-yellow-100 text-yellow-800"
                          : method.state === "Failed"
                          ? "bg-red-100 text-red-800"
                          : "bg-gray-100 text-gray-800"
                      }`}
                    >
                      {method.state}
                    </span>
                  </div>
                  <span className="text-gray-600">
                    {method.maskedCardNumber}
                  </span>
                </div>
              ))}
          </div>
        </div>
      )}

      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-medium mb-4">Invoices</h3>
        {loadingInvoices ? (
          <div className="flex justify-center py-4">
            <Loader />
          </div>
        ) : invoices.length === 0 ? (
          <p className="text-gray-500 text-center py-4">No invoices found</p>
        ) : (
          <div className="flex flex-col gap-2">
            <div className="grid grid-cols-4 px-4 py-2 bg-gray-50 rounded-lg text-sm font-medium">
              <div>Created</div>
              <div>Amount</div>
              <div>Status</div>
              <div>Invoice ID</div>
            </div>
            
            {invoices.map((invoice) => (
              <div 
                key={invoice.id}
                className="grid grid-cols-4 px-4 py-3 bg-white border rounded-lg items-center hover:bg-gray-50 transition-colors"
              >
                <div className="text-gray-900">
                  {formatDate(invoice.created)}
                </div>
                <div className="text-gray-900">{`${invoice.amount} ${invoice.currency}`}</div>
                <div>
                  <span
                    className={`text-sm px-2 py-1 rounded ${
                      ["Created", "Pending", "Authorized", "Dunning"].includes(
                        invoice.state
                      )
                        ? "bg-yellow-100 text-yellow-800"
                        : invoice.state === "Failed"
                        ? "bg-red-100 text-red-800"
                        : invoice.state === "Settled"
                        ? "bg-green-100 text-green-800"
                        : "bg-gray-100 text-gray-800"
                    }`}
                  >
                    {invoice.state}
                  </span>
                </div>
                <div className="text-gray-900">{invoice.id}</div>
              </div>
            ))}

            {invoices.length > 0 && hasMore && (
              <div className="flex justify-center mt-4">
                <button
                  onClick={handleManagePaymentMethods}
                  className="text-blue-600 hover:text-blue-800 text-sm font-medium"
                >
                  See More
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Billing;
