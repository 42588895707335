import React from 'react';
import { useAuth } from '../context/AuthContext';
import { ROUTES } from '../config/routes';
import { Button } from './Button';
import { ReactComponent as Exit } from '../assets/icons/exit.svg';
import { NavigationLink } from './NavigationLink';

const Navigation: React.FC = () => {
  const { logout, isActionRequired } = useAuth();

  return (
    <nav className="h-full flex flex-col">
      <ul className="flex flex-col gap-2 flex-1">
        <li>
          <NavigationLink to={ROUTES.HOME()}>Dashboard</NavigationLink>
        </li>
        <li>
          <NavigationLink to={ROUTES.TRANSACTIONS()}>
            Transactions
          </NavigationLink>
        </li>
        <li>
          <NavigationLink to={ROUTES.INSIGHTS()}>Insights</NavigationLink>
        </li>
        <li>
          <NavigationLink to={ROUTES.PROFILE()}>Profile</NavigationLink>
        </li>
        <li className="relative">
          <NavigationLink to={ROUTES.BILLING()}>
            <span className="flex items-center gap-2">
              Billing
              {isActionRequired && (
                <span className="px-2 py-0.5 text-xs bg-red-100 text-red-800 rounded">
                  Action Required
                </span>
              )}
            </span>
          </NavigationLink>
        </li>
        <li>
          <NavigationLink to={ROUTES.BENEFITS()}>Benefits</NavigationLink>
        </li>
        <li>
          <NavigationLink to={ROUTES.BUSINESSES()}>Business</NavigationLink>
        </li>
      </ul>
      <div className="mt-auto pt-4">
        <NavigationLink 
          to={ROUTES.TERMS_OF_SERVICE()} 
          className="font-normal text-gray-600 hover:text-gray-700"
        >
          Terms of Service
        </NavigationLink>
        <Button
          variant="ghost"
          onClick={logout}
          className="text-red gap-2 flex items-center hover:text-red-400"
        >
          <Exit />
          Log out
        </Button>
      </div>
    </nav>
  );
};

export default Navigation;
