import api from './axiosConfig';

export interface Company {
    name: string;
    email: string;
    cvrNumber: string;
    hasPendingChanges: boolean | null;
  }

  export interface UpdateCompanyInput {
    name: string;
    email: string;
    cvrNumber: string;
  }


  export const updateCompany = async (input: UpdateCompanyInput) => {
    try {
      const response = await api.post('/business-account/update-company', {
        UpdateCompanyInput: input
      });
      return response.data;
    } catch (error) {
      console.error('Updating company error:', error);
      throw error;
    }
  };

  export const getCompany = async (): Promise<Company> => {
    try {
      const response = await api.post('/business-account/get-company', {});
      return response.data;
    } catch (error) {
      console.error('Getting company error:', error);
      throw error;
    }
  };