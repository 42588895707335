import api from "./axiosConfig";

export interface PaymentMethod {
  cardProvider: string;
  maskedCardNumber: string;
  state: 'Pending' | 'Active' | 'Inactivated' | 'Failed' | 'Deleted';
}

export interface BillingInfo {
  actionRequired: boolean;
  hasUnpaidInvoices: boolean;
  hasActivePaymentMethod: boolean;
  paymentMethods: PaymentMethod[];
}

export interface BillingResponse {
  billingInfo: BillingInfo | null;
}

export interface CustomerPortalResponse {
  customerPortalUrl: string;
}

export interface PaymentMethodFormResponse {
  formUrl: string;
}

export interface Invoice {
  id: string;
  currency: string;
  amount: string;
  state: 'Created' | 'Pending' | 'Authorized' | 'Dunning' | 'Settled' | 'Cancelled' | 'Failed';
  created: string;
}

export interface ListInvoicesResponse {
  invoices: Invoice[];
  hasMore: boolean;
}

export const getBillingInfo = async (): Promise<BillingResponse> => {
  try {
    const response = await api.post('/business-account/get-billing-info', {});
    return response.data;
  } catch (error) {
    console.error('Getting billing info error:', error);
    throw error;
  }
};

export const getCustomerBillingPortalAccess = async (): Promise<CustomerPortalResponse> => {
  try {
    const response = await api.post('/business-account/get-customer-billing-portal-access', {});
    return response.data;
  } catch (error) {
    console.error('Getting customer billing portal access error:', error);
    throw error;
  }
};

export const getPaymentMethodForm = async (): Promise<PaymentMethodFormResponse> => {
  try {
    const response = await api.post('/business-account/get-payment-method-form', {});
    return response.data;
  } catch (error) {
    console.error('Getting payment method form error:', error);
    throw error;
  }
};

export const listInvoices = async (): Promise<ListInvoicesResponse> => {
  try {
    const response = await api.post('/business-account/list-invoices', {});
    return response.data;
  } catch (error) {
    console.error('Listing invoices error:', error);
    throw error;
  }
};
